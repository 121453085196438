<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="学期" prop="s_id">
				  <el-select v-model="form.s_id" placeholder="请选择学期" style="width: 100%;">
				    <el-option :value="item.id" :label="item.year+' '+item.tag" v-for="(item,index) in semester" :key="index"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="活动名称" prop="title">
				  <el-input v-model="form.title" placeholder="请输入活动名称" type="text" clearable />
				</el-form-item>
				<el-form-item label="活动类型" prop="type_id">
				  <el-select v-model="form.type_id" placeholder="请选择活动类型" style="width: 100%;">
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in types" :key="index"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="活动时间" prop="time" style="width: 450px;">
				  <el-date-picker
					v-model="form.time"
					type="date"
					value-format="yyyy-MM-dd"
					class="w-100"
					placeholder="请选择活动时间">
				  </el-date-picker>
				</el-form-item>
				<el-form-item label="活动计划" prop="content">
				   <tinymce ref="editor" :key="tinymceFlag" v-model="form.content" />
				</el-form-item>
				<el-form-item label="上传材料" prop="files">
					<div class="flex flex-column flex-wrap">
						<div class="flex mr-1 mb-2">
							<el-upload
							  v-for="(item,index) in files" :key="index"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <div class="position-relative flex align-center flex-wrap w-100 overflow-hidden" style="height: 100px;" v-if="item.id">
								<img :src="item.path" style="width: 100px;height: 100px;" v-if="item.ext == 'jpg' || item.ext == 'jpeg' || item.ext == 'png'">
							  	<span style="width: 100px;word-wrap:break-word;word-break:break-all;overflow: hidden;" v-else>{{item.name || ''}}</span>
							  	<i class="el-icon-delete text-white position-absolute flex-all" style="width: 25px;height: 25px;top: 0;right: 0;background-color: rgba(0,0,0,0.4);cursor: pointer;" @click.stop="delFile(index)"></i>
							  </div>
							  <i v-else class="el-icon-plus uploader-icon"></i>
							</el-upload>
							<el-upload
							  v-if="files.length < 5"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <i class="el-icon-plus uploader-icon"></i>
							</el-upload>
						</div>
						<small class="d-block text-secondary" style="line-height: 20px;">上传照片及相关资料</small>
					</div>
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
import tinymce from "@/components/common/tinymce.vue"
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
		tinymce
	},
	data() {
		return {
			preUrl: 'user_sfk',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
			},
			form:{
				s_id:'',
				title:'',
				type_id:1,
				time:'',
				content:'',
				files:null,
			},
			rules:{
				s_id: [
				  { required: true, message: '请选择学期', trigger: 'blur' }
				],
				type_id: [
				  { required: true, message: '请选择活动类型', trigger: ['blur','change'] }
				],
				time: [
				  { required: true, message: '请选择活动时间', trigger: 'blur' }
				],
				content: [
				  { required: true, message: '请输入活动计划', trigger: 'blur' }
				],
				title: [
				  { required: true, message: '请填写活动名称', trigger: ['blur','change'] }
				],
				files: [
				  { required: true, message: '请上传材料', trigger: ['blur','change'] }
				],
			},
			types:[
				{id:1,name:'校级'},
				{id:2,name:'市级'},
			],
			tinymceFlag:0,
			files:[],
			fileList:[],
		}
	},
	mounted() {
		if(this.$route.query.info){
			 this.form = this.$route.query.info
			 if(this.$route.query.info.files){
			     this.files = this.$route.query.info.files
			 }
		}
		this.getsemester()
	},
	methods:{
		reset() {
		  this.form = {
			 s_id:'',
			 title:'',
			 type_id:1,
			 time:'',
			 content:'',
			 files:null,
		  }
		  this.resetForm('form')
		},
		uploadSuccess(e){
		   if(e.status){
			 this.files.push(e.data)
		   }
		},
		beforeUpload(file){
			let fileArr = file.name.split('.')
			let suffix = fileArr[fileArr.length - 1]
			//只能导入图片文件
			if (!/(jpg|jpeg|png|gif|pdf|doc|docx)/i.test(suffix)) {
				this.$message.error('文件格式不正确')
				return false
			}
			return true
		},
		delFile(index){
			this.files.splice(index,1)
		},
		handleSubmit() {
		  this.form.files = this.files
		  this.$refs.form.validate(valid => {
		    if (valid) {
			 
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('申请成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
	},
}
</script>

<style>
</style>